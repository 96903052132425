// Define the browser detection function
function getBrowserName() {
  const userAgent = navigator.userAgent;
  let browserName = 'unknown';

  if (/chrome|crios|crmo/i.test(userAgent) && !/edge|edg|opr|brave/i.test(userAgent)) {
    browserName = 'chrome';
  } else if (/firefox|fxios/i.test(userAgent)) {
    browserName = 'firefox';
  } else if (/safari/i.test(userAgent) && !/chrome|crios|crmo|android/i.test(userAgent)) {
    browserName = 'safari';
  } else if (/opr\//i.test(userAgent)) {
    browserName = 'opera';
  } else if (/edg/i.test(userAgent)) {
    browserName = 'edge';
  } else if (/brave/i.test(userAgent)) {
    browserName = 'brave';
  }

  // console.log('browserName', browserName);
  return browserName;
}

// Function to update extension upsell components
function updateExtensionUpsell($container) {
  const browserName = getBrowserName();

  const $extensionNameElements = $container.find('.browser-extension-name');
  const $browserNameElements = $container.find('.browser-name');
  const $links = $container.find('.extension-link');
  const $icons = $container.find('.browser-icon');

  let extensionName = 'Chrome Extension';
  let storeLink = $container.data('chrome-link');
  let browserDisplayName = 'Chrome';
  let iconClass = 'fa-brands fa-chrome';

  switch (browserName) {
    case 'chrome':
    case 'edge':
    case 'brave':
      // Defaults for Chrome-compatible browsers
      break;
    case 'firefox':
      extensionName = 'Firefox Add-on';
      storeLink = $container.data('firefox-link');
      browserDisplayName = 'Firefox';
      iconClass = 'fa-brands fa-firefox-browser';
      break;
    default:
      extensionName = 'Browser Extension';
      storeLink = 'https://www.notcommon.com/extensions';
      browserDisplayName = 'your browser';
      iconClass = 'fa-solid fa-globe';
      break;
  }

  // Update the extension name in headings or texts
  $extensionNameElements.text(extensionName);

  // Update all browser name elements
  $browserNameElements.text(browserDisplayName);

  // Update links
  $links.each(function() {
    const $link = $(this);
    $link.attr('href', storeLink);
  });

  // Update icons
  $icons.each(function() {
    const $icon = $(this);
    $icon.attr('class', iconClass + ' mr-2 browser-icon');
  });
}

document.addEventListener('turbolinks:load', () => {

  // Show spinner inside the submit button when a form with class .auth-form is submitted
  $(".auth-form").on("submit", function (e) {
    // Do not show loader if form is not in valid state
    if ($(this).valid() === false) { return; }

    // Spinning icon from font awesome
    const loadingHtmlBtn = `
        <i class="fa-solid fa-circle-notch fa-spin"></i>
      `;
    const submitBtn = $(this).find('button[type="submit"]'); // Find form submit button
    submitBtn.attr("disabled", "disabled"); // Disable it
    submitBtn.attr("data-text", submitBtn.text()); // Save original text in data-text attribute
    submitBtn.html(loadingHtmlBtn); // Set inner html of button to the spinning icon
  });


  // SHOW LOADING SPINNER AND MESSAGE WHEN SUBMITTING FORM
  // Apply to any form with the class 'loading-on-submit'
  $("form.loading-on-submit").on("submit", function (e) {
    const form = $(this);

    // Get the loading text from data attribute or default to 'Loading...'
    const loadingText = form.data('loading-text') || 'Loading...';

    // HTML for the loading state
    const loadingHtmlBtn = `
      <i class="fa-solid fa-circle-notch fa-spin"></i>
      <span class="ml-2">${loadingText}</span>
    `;

    // Find the submit button(s) within the form
    const submitBtn = form.find('button[type="submit"], input[type="submit"], button:not([type])');

    // Disable the submit button(s) and set the loading HTML or value
    submitBtn.each(function() {
      const btn = $(this);
      btn.attr("disabled", "disabled");

      if (btn.is('button')) {
        btn.html(loadingHtmlBtn);
      } else if (btn.is('input')) {
        btn.val(loadingText);
      }
    });
  });


  // Update all extension upsell components
  const $upsellContainers = $('[data-extension-upsell]');
  $upsellContainers.each(function() {
    const $container = $(this);
    updateExtensionUpsell($container);
  });


});
